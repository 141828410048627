// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adatvedelem-js": () => import("./../../../src/pages/adatvedelem.js" /* webpackChunkName: "component---src-pages-adatvedelem-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-projektjeink-js": () => import("./../../../src/pages/projektjeink.js" /* webpackChunkName: "component---src-pages-projektjeink-js" */),
  "component---src-pages-rolunk-js": () => import("./../../../src/pages/rolunk.js" /* webpackChunkName: "component---src-pages-rolunk-js" */),
  "component---src-pages-tamogatas-js": () => import("./../../../src/pages/tamogatas.js" /* webpackChunkName: "component---src-pages-tamogatas-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-uvegzseb-js": () => import("./../../../src/pages/uvegzseb.js" /* webpackChunkName: "component---src-pages-uvegzseb-js" */),
  "component---src-templates-blog-posts-js": () => import("./../../../src/templates/blogPosts.js" /* webpackChunkName: "component---src-templates-blog-posts-js" */)
}

